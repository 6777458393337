import React                        from 'react';
import PropTypes                    from 'prop-types';
import {getUrlWithinsertedGetParam} from './../utils/CommonUtils';

class PaginationItem extends React.Component {

	constructor(props) {
		super(props);

		this.goToPage = this.goToPage.bind(this);
	}

	goToPage(e) {
		this.props.goToPage(e, this.props.index);
	}

	render() {
		return (
			<li key={'pagination-' + this.props.index} className="pagination__item">
				<a className={'pagination__link' + (this.props.index === this.props.currentPage ? ' is-active' : '')}
				   href={getUrlWithinsertedGetParam('page', this.props.index)}
				   onClick={this.goToPage}
				>{this.props.index}</a>
			</li>
		);
	}
}

PaginationItem.propTypes = {
	currentPage: PropTypes.number.isRequired,
	goToPage:    PropTypes.func.isRequired,
	index:       PropTypes.number.isRequired,
};

export default PaginationItem;