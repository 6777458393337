import React                                                             from 'react';
import PropTypes                                                         from 'prop-types';
import {getUrlWithinsertedGetParam, scrollToElem, scrollContainerToElem} from './../utils/CommonUtils';
import PaginationItem                                                    from './PaginationItem';
import Translations                                                      from "./../utils/Translations";

class Pagination extends React.Component {

	constructor(props) {
		super(props);

		this.goToFirstPage = this.goToFirstPage.bind(this);
		this.goToLastPage  = this.goToLastPage.bind(this);
		this.goToPage      = this.goToPage.bind(this);
		this.getPrevPage   = this.getPrevPage.bind(this);
		this.getNextPage   = this.getNextPage.bind(this);
		this.goPrev        = this.goPrev.bind(this);
		this.goNext        = this.goNext.bind(this);
	}

	goToFirstPage(e) {
		this.goToPage(e, 1);
	}

	goToLastPage(e) {
		this.goToPage(e, this.props.maxPage);
	}

	goToPage(e, page) {
		if (this.props.scrollOnGoTo) {
			if (this.props.containerId) {
				if (this.props.scrollContainerId) {
					scrollContainerToElem(this.props.containerId, this.props.scrollContainerId, false);
				} else {
					scrollToElem(this.props.containerId, false);
				}
			} else {
				scrollToElem('body-container');
			}
		}
		e.preventDefault();
		this.props.goToFn(page);

	}

	getPrevPage() {
		return (this.props.currentPage - 1) < 1 ? 1 : (this.props.currentPage - 1);
	}

	getNextPage() {
		return (this.props.currentPage + 1) > this.props.maxPage ? this.props.maxPage : (this.props.currentPage + 1);
	}

	goPrev(e) {
		this.goToPage(e, this.getPrevPage());
	}

	goNext(e) {
		this.goToPage(e, this.getNextPage());
	}

	render() {
		let i, start, end, showRightSpacer;
		const spacerElement = <li className="pagination__item h-text-grey"><strong>...</strong></li>;
		const maxPage       = this.props.maxPage;
		const curPage       = this.props.currentPage;
		const isMobile      = this.props.isMobile;

		if (isMobile) {
			start           = curPage <= 2 ? 2 : curPage - 1;
			end             = curPage + 1 < maxPage ? curPage + 1 : ((curPage + 1 === maxPage) ? curPage : maxPage - 1);
			showRightSpacer = end + 1 < maxPage;
		} else {
			const len       = 5;
			start           = (curPage <= len) ? 2 : curPage - (Math.floor(len / 2));
			end             = (curPage < maxPage - (Math.ceil(len / 2))) ? (curPage + Math.ceil(len / 2)) : maxPage;
			showRightSpacer = (curPage < maxPage && (curPage + Math.floor(len / 2) + 2) < maxPage && (end + 1 <= maxPage));
			if (curPage + 4 >= maxPage) {
				end = maxPage - 1;
			}
		}


		// pagination item list
		const paginationItemList = [];
		if (maxPage > 1) {
			for (i = start; i <= end; i++) {
				paginationItemList.push(<PaginationItem key={"pagination-item-" + i}
				                                        currentPage={this.props.currentPage}
				                                        goToPage={this.goToPage}
				                                        index={i}
				/>);
			}
		}
		return (
			<ul className={"pagination h-list--horizontal" + (this.props.inline ? ' h-disp-inline-block' : '')}>
				{/* Arrow prev */}
				<li className={("pagination__item" + (isMobile ? ' -size-small' : ''))}>
					<a className={"pagination__link -function-navigation" + (this.props.currentPage === 1 ? ' is-inactive' : '')}
					   href={getUrlWithinsertedGetParam('page', this.getPrevPage())}
					   onClick={this.goPrev}
					>
						{isMobile && <span className="icon -icon-single-arrow-left-line" />}
						{!isMobile &&
						<span className="pagination__link__icon"><i className="icon -icon-complete-arrow-left-line" /><span className="h-text-italic">{Translations.get('PaginationBack')}</span></span>}
					</a>
				</li>

				{/* First Item  */}
				<li className="pagination__item">
					<a className={'pagination__link' + (maxPage <= 1 ? ' is-inactive' : '') + (this.props.currentPage === 1 ? ' is-active' : '')}
					   href={getUrlWithinsertedGetParam('page', 1)}
					   onClick={this.goToFirstPage}
					>1</a>
				</li>

				{/* Spacer Element (...) */}
				{(curPage > 1 && start > 2) && spacerElement}

				{/* pagination items */}
				{paginationItemList}

				{/* Spacer Element (...) */}
				{showRightSpacer && spacerElement}

				{/* Last Item  */}
				{maxPage > 1 && <li className="pagination__item 1234">
					<a className={'pagination__link' + (this.props.currentPage === this.props.maxPage ? ' is-active' : '')}
					   href={getUrlWithinsertedGetParam('page', this.props.maxPage)}
					   onClick={this.goToLastPage}
					>{this.props.maxPage}</a>
				</li>}

				{/* Arrow next */}
				<li className={("pagination__item" + (isMobile ? ' -size-small' : ''))}>
					<a className={"pagination__link -function-navigation" + ((this.props.currentPage >= maxPage || curPage - 1 >= end) ? ' is-inactive' : '')}
					   href={getUrlWithinsertedGetParam('page', this.getNextPage())}
					   onClick={this.goNext}
					>
						{isMobile && <span className="icon -icon-single-arrow-right-line" />}
						{!isMobile &&
						<span className="pagination__link__icon"><i className="icon -icon-complete-arrow-right-line" /><span className="h-text-italic">{Translations.get('PaginationForward')}</span></span>}
					</a>
				</li>
			</ul>
		);
	}
}

Pagination.propTypes = {
	containerId:       PropTypes.string,
	currentPage:       PropTypes.number.isRequired,
	isMobile:          PropTypes.bool,
	maxPage:           PropTypes.number,
	goToFn:            PropTypes.func.isRequired,
	scrollContainerId: PropTypes.string,
	scrollOnGoTo:      PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
	inline:            PropTypes.bool,
};

Pagination.defaultProps = {
	scrollOnGoTo: '',
	inline:       false,
};

export default Pagination;
